import React from "react";
import classNames from "classnames/bind";
import { Row } from "react-bootstrap";
import Section from "../Section";

import styles from "./index.module.scss";

let classNamesBound = classNames.bind(styles);

class Hero extends React.Component {
	render() {
		let heroContainerClasses = classNamesBound({
			hero: true,
			[`${this.props.type}Hero`]: this.props.type,
			[`${this.props.className}`]: this.props.className
		});

		return (
			<Section
				fluid={true}
				backgroundType={this.props.backgroundType}
				className={heroContainerClasses}
			>
				<Row className={styles.heroRow}>{this.props.children}</Row>
			</Section>
		);
	}
}

export default Hero;
