import React from "react";
import classNames from "classnames/bind";

import styles from "./index.module.scss";

let classNamesBound = classNames.bind(styles);

class Heading extends React.Component {
	render() {
		let headingClasses = classNamesBound({
			heading: true,
			[`${this.props.themeType}Theme`]: this.props.themeType,
			limitMargin: this.props.limitMargin
		});

		return <h2 className={headingClasses}>{this.props.text}</h2>;
	}
}

export default Heading;
