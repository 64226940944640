import React from "react";
import classNames from "classnames/bind";

import styles from "./index.module.scss";

let classNamesBound = classNames.bind(styles);

class Background extends React.Component {
  render() {
    let backgroundClasses = classNamesBound({
      [`${this.props.themeType}Theme`]: this.props.themeType
    });
    return <div className={backgroundClasses}>{this.props.children}</div>;
  }
}

export default Background;
