import React from "react";
import classNames from "classnames/bind";
import { Container } from "react-bootstrap";

import styles from "./index.module.scss";

let classNamesBound = classNames.bind(styles);

class Section extends React.Component {
  render() {
    let fluidContainerClasses = classNamesBound({
      fluidContainer: true,
      [`${this.props.backgroundType}Background`]: this.props.backgroundType,
      [`${this.props.className}`]: this.props.className
    });

    let fixedContainerClasses = classNamesBound({
      fixedContainer: true,
      extraLeftPadding: this.props.extraLeftPadding
    });

    return (
      <Container fluid={this.props.fluid} className={fluidContainerClasses}>
        <Container className={fixedContainerClasses}>
          {this.props.children}
        </Container>
      </Container>
    );
  }
}

export default Section;
