import React from "react";
import { Col } from "react-bootstrap";

import styles from "./index.module.scss";

class MotifColumn extends React.Component {
  render() {
    return (
      <Col xs={this.props.xs} lg={this.props.lg} className={styles.motifColumn}>
        {this.props.children}
      </Col>
    );
  }
}

MotifColumn.defaultProps = {
  xs: 12,
  lg: 6
};

export default MotifColumn;
