import React from "react";
import { Container, Col, Row, Nav } from "react-bootstrap";
import { Link, injectIntl } from "gatsby-plugin-intl";
import Mailchimp from "react-mailchimp-form";

import styles from "./index.module.scss";
import shiftLogo from "../../img/shift-logo-white.svg";
import facebookIcon from "../../img/icon-facebook.svg";
import twitterIcon from "../../img/icon-twitter.svg";
import instagramIcon from "../../img/icon-instagram.svg";

class Footer extends React.Component {
  render() {
    const intl = this.props.intl;
    return (
      <>
        <Container fluid={true} className={styles.footerFluidContainer}>
          <Container as="footer" className={styles.footerFixedContainer}>
            <Row>
              <Col xs={12} lg={2} className={styles.shiftLogoColumn}>
                <img src={shiftLogo} width="84" height="24" alt="Shift Logo" />
              </Col>
              <Col xs={12} sm={12} lg={4} className={styles.addressColumn}>
                <Row>
                  <Col xs={12} sm={6} lg={12}>
                    <address>
                      <span className={styles.addressCityCountry}>
                        Global Offices
                      </span>
                      Calgary 1-800-769-2025 <br />
                      Edmonton 1-780-229-5402 <br />
                      Vancouver 1-778-650-5230 <br />
                      United Kingdom 44-(0)-7484745598 <br />
                      United States 1-844-296-1646 <br />
                      <br />
                      <a href="mailto:info@shiftconnect.com">
                        info@shiftconnect.com
                      </a>

                    </address>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} lg={6}>
                <Row>
                  <Col xs={12} sm={6} lg={4}>
                    <Nav className="flex-column">
                      <Link to="/personal" className={styles.navLink}>
                        Personal
                      </Link>
                      <Link to="/business" className={styles.navLink}>
                        Business
                      </Link>
                      <Link to="/" className={styles.navLink}>
                        About Us
                      </Link>
                      <Link to="/blog" className={styles.navLink}>
                        Blog
                      </Link>
                      <Link to="/verification" className={styles.navLink}>
                        Verification
                      </Link>
                      <Link to="/security" className={styles.navLink}>
                        Security
                      </Link>
                    </Nav>
                  </Col>
                  <Col xs={12} sm={6} lg={4}>
                    <Nav defaultActiveKey="/home" className="flex-column">
                      <Link to="/faq" className={styles.navLink}>
                        FAQ
                      </Link>

                      <Link to="/secure-upload" className={styles.navLink}>
                        Secure Upload
                      </Link>

                      <Link to="/compliance" className={styles.navLink}>
                        Compliance
                      </Link>
                      <Link to="/terms-privacy" className={styles.navLink}>
                        Terms of Service and Privacy Policy
                      </Link>
                      <Link to="/whistleblower" className={styles.navLink}>
                        Whistleblower Form
                      </Link>
                      <a
                        href="mailto:careers@shiftconnect.com"
                        className={styles.navLink}
                      >
                        Careers
                      </a>
                    </Nav>
                  </Col>
                  <Col xs={12} lg={4} className={styles.accountLinks}>
                    <Nav className="flex-column">
                      <a
                        href={intl.formatMessage({id: 'footer.navigation.personal.link'})}
                        className={styles.navLinkStrong}
                      >
                         {intl.formatMessage({id: 'footer.navigation.personal.label'})}
                      </a>
                      <a
                        href={intl.formatMessage({id: 'footer.navigation.business.link'})}
                        className={styles.navLinkStrong}
                      >
                        {intl.formatMessage({id: 'footer.navigation.business.label'})}
                      </a>
                      <div className={styles.socialLinks}>
                        <a href="https://www.facebook.com/ShiftConnectMoneyTransfer/">
                          <img
                            src={facebookIcon}
                            width="24"
                            height="24"
                            alt="Facebook Icon"
                          />
                        </a>
                        <a href="https://twitter.com/Shiftconnect">
                          <img
                            src={twitterIcon}
                            width="24"
                            height="24"
                            alt="Twitter Icon"
                          />
                        </a>
                        <a href="https://www.instagram.com/shiftconnect/">
                          <img
                            src={instagramIcon}
                            width="24"
                            height="24"
                            alt="Instagram Icon"
                          />
                        </a>
                      </div>
                    </Nav>
                  </Col>
                  <Col xs={12} className={styles.newsletterColumn}>
                    <h2 className={styles.newsletterHeading}>
                      Market News Signup
                    </h2>
                    <Mailchimp
                      action="https://shiftconnect.us8.list-manage.com/subscribe/post?u=71003fc4d41d5647c6728e424&amp;id=55df9920c3"
                      fields={[
                        {
                          name: "EMAIL",
                          placeholder: "Email",
                          type: "email",
                          required: true
                        }
                      ]}
                      messages={{
                        sending: "Sending...",
                        success: "Thank you for subscribing!",
                        error:
                          "An error has occurred. Please contact us at info@shiftconnect.com.",
                        empty: "Please provide your e-mail address.",
                        duplicate:
                          "Too many subscribe attempts for this email address.",
                        button: "Subscribe!"
                      }}
                      styles={{
                        sendingMsg: {
                          color: "#f7f6fd"
                        },
                        successMsg: {
                          color: "#ecfef6"
                        },
                        duplicateMsg: {
                          color: "#fedf4a"
                        },
                        errorMsg: {
                          color: "#fedf4a"
                        }
                      }}
                      className={styles.mailchimp}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container
          fluid={true}
          className={styles.footerCopyrightFluidContainer}
        >
          <Container className={styles.footerCopyrightFixedContainer}>
            <Row className={styles.footerCopyrightRow}>
              <Col>
                <p>
                  &copy; SHIFT Connect 2019 | {intl.formatMessage({id: 'footer.disclaimer.text'})}
                </p>
              </Col>
            </Row>
          </Container>
        </Container>
      </>
    );
  }
}

export default injectIntl(Footer);
