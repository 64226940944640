import React from "react";
import {Helmet} from "react-helmet";
import {graphql, StaticQuery, withPrefix} from "gatsby";

import Footer from "../Footer";
import Navigation from "../Navigation";

import "bootstrap/dist/css/bootstrap.css";
import "../../css/global.scss";
import styles from "./index.module.scss";

const myQuery = graphql`
    query SiteTitleQuery {
        site {
            siteMetadata {
                title
            }
        }
    }
`

const TemplateWrapper = ({children}) => {
    return (
        <>
            <Helmet>
                <html lang="en"/>
                <StaticQuery
                    query={`${myQuery}`}
                    render={data => (
                        <>
                            <title>{data.site.siteMetadata.title}</title>
                            <meta
                                name="description"
                                content={data.site.siteMetadata.description}
                            />
                            <meta
                                property="og:title"
                                content={data.site.siteMetadata.title}
                            />
                        </>
                    )}
                />


                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href={`${withPrefix("/")}img/apple-touch-icon.png`}
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={`${withPrefix("/")}img/favicon-32x32.png`}
                    sizes="32x32"
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={`${withPrefix("/")}img/favicon-16x16.png`}
                    sizes="16x16"
                />

                <link
                    rel="mask-icon"
                    href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
                    color="#ff4400"
                />

                <meta name="theme-color" content="#fff"/>

                <meta property="og:type" content="business.business"/>

                <meta property="og:url" content="/"/>
                <meta
                    property="og:image"
                    content={`${withPrefix("/")}img/og-image.jpg`}
                />
                <meta name="facebook-domain-verification" content="rx28y052uvcru474jps4swvhdgtfhv"/>

            </Helmet>
            <Navigation/>
            <div className={styles.fullHeight}>
                {children}
            </div>
            <Footer/>
        </>
    );
};

export default TemplateWrapper;
