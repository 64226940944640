import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { injectIntl } from 'gatsby-plugin-intl';

import Heading from "../Heading";
import Button from "../Button";

import styles from "./index.module.scss";

class Questions extends React.Component {

	render() {
		const intl = this.props.intl;
		return (
			<Container fluid={true} className={styles.fluidContainer}>
				<Container>
					<Row>
						<Col xs={12} lg={3} className={styles.headingColumn}>
							<Heading text={intl.formatMessage({ id: "questions.title" })} />
						</Col>
						<Col xs={12} lg={5} className={styles.textColumn}>
							<p>{intl.formatMessage({ id: "questions.text" })}</p>
						</Col>
						<Col xs={12} lg={4} className={styles.buttonColumn}>
							<div className={styles.buttonWrapper}>
								<Button
									to="/contact"
									color={"darkPurple"}
								>
									{intl.formatMessage({ id: "questions.button.text" })}
								</Button>
							</div>
						</Col>
					</Row>
				</Container>
			</Container>
		);
	}
}

export default injectIntl(Questions);
