import React from "react";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Dropdown,
} from "react-bootstrap";
import {
  Link,
  IntlContextConsumer,
  changeLocale,
  injectIntl,
} from "gatsby-plugin-intl";

import styles from "./index.module.scss";

import shiftLogo from "../../img/shift-logo-black.svg";

import allLanguages from "../../../i18n.json";

const getCountryFromLocale = (locale) => {
  const parts = locale.split("-");
  let country = parts[0];
  if (parts.length === 2) {
    country = parts[1];
  }
  return country;
};

class Navigation extends React.Component {
  render() {
    const intl = this.props.intl;
    return (
      <Navbar expand="xl" sticky="top" className={styles.navbar}>
        <Container className={styles.navbarContainer}>
          <Link to="/" className={styles.brand}>
            <img src={shiftLogo} width="84" height="24px" alt="Shift Logo" />
          </Link>
          <div className={styles.buttonToggleWrapper}>
          <IntlContextConsumer>
                {({ languages, language: currentLocale }) => (
                  <NavDropdown
                    title=""
                    className={`${styles.localeLink} ${styles.navButtonMobile} ${styles.flag} ${styles.selectedFlag} ${getCountryFromLocale(
                      currentLocale
                    ).toUpperCase()}`}
                  >
                  {languages
                    .filter((language) => language !== currentLocale)
                    .map((language) => (
                      <Dropdown.Item
                        key={language}
                        eventKey={language}
                        onSelect={(eventKey) => changeLocale(eventKey)}
                        className={`${styles.flag} ${getCountryFromLocale(
                          language
                        ).toUpperCase()}`}
                      >
                        {allLanguages.filter((l) => l.code === language)[0].name}
                      </Dropdown.Item>
                    ))}
                  </NavDropdown>
                )}
              </IntlContextConsumer>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className={styles.navbarToggler}
            />
          </div>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className={`${styles.navbarNav} mr-auto`}>
              <Link
                to="/"
                className={styles.navLink}
                activeClassName={styles.navLinkActive}
              >
                Home
              </Link>
              <Link
                to="/business"
                className={styles.navLink}
                activeClassName={styles.navLinkActive}
              >
                Business
              </Link>
              <Link
                  to="/personal"
                  className={styles.navLink}
                  activeClassName={styles.navLinkActive}
              >
                Personal
              </Link>
              <Link
                to="/services"
                className={styles.navLink}
                activeClassName={styles.navLinkActive}
              >
                Services
              </Link>
              <Link
                to="/partnerships"
                className={styles.navLink}
                activeClassName={styles.navLinkActive}
              >
                Partnerships
              </Link>
              <Link
                to="/security"
                className={styles.navLink}
                activeClassName={styles.navLinkActive}
              >
                Security
              </Link>
              <Link
                to="/blog"
                className={styles.navLink}
                activeClassName={styles.navLinkActive}
              >
                Blog
              </Link>
            </Nav>
            <Nav className={`${styles.navbarNav} ${styles.navbarNavSecondary}`}>
              <IntlContextConsumer>
                {({ languages, language: currentLocale }) => (
                  <NavDropdown
                    title=""
                    className={`${styles.navLink} ${styles.localeLink} ${styles.navButtonDesktop} ${styles.flag} ${styles.selectedFlag} ${getCountryFromLocale(
                      currentLocale
                    ).toUpperCase()}`}
                  >
                  {languages
                    .filter((language) => language !== currentLocale)
                    .map((language) => (
                      <Dropdown.Item
                        key={language}
                        eventKey={language}
                        onSelect={(eventKey) => changeLocale(eventKey)}
                        className={`${styles.flag} ${getCountryFromLocale(
                          language
                        ).toUpperCase()}`}
                      >
                        {allLanguages.filter((l) => l.code === language)[0].name}
                      </Dropdown.Item>
                    ))}
                  </NavDropdown>
                )}
              </IntlContextConsumer>
              <NavDropdown
                title="Login"
                className={`${styles.navLink} ${styles.loginLink}`}
              >
                <NavDropdown.Item
                  eventKey="personal-login"
                  href={intl.formatMessage({
                    id: "navigation.login.personal.link",
                  })}
                  bsPrefix={`dropdown-item ${styles.personalLogin}`}
                >
                  {intl.formatMessage({
                    id: "navigation.login.personal.label",
                  })}
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="business-login"
                  href={intl.formatMessage({
                    id: "navigation.login.business.link",
                  })}
                  bsPrefix={`dropdown-item ${styles.businessLogin}`}
                >
                  {intl.formatMessage({
                    id: "navigation.login.business.label",
                  })}
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default injectIntl(Navigation);
