import React from "react";
import { Link } from "gatsby-plugin-intl";
import classNames from "classnames/bind";

import styles from "./index.module.scss";

let classNamesBound = classNames.bind(styles);

class Button extends React.Component {
	render() {
		let buttonClasses = classNamesBound({
			button: true,
			[`${this.props.color}`]: this.props.color,
			navButton: this.props.navButton,
			[`${this.props.className}`]: this.props.className
		});

		let button;

		if (this.props.to) {
			button = (
				<Link
					className={buttonClasses}
					to={this.props.to}
					type={this.props.type}
					onClick={this.props.onClick}
				>
					{this.props.children}
				</Link>
			);
		} else if (this.props.href) {
			button = (
				<a
					className={buttonClasses}
					href={this.props.href}
					type={this.props.type}
					onClick={this.props.onClick}
				>
					{this.props.children}
				</a>
			);
		} else if (this.props.type) {
			button = (
				<button className={buttonClasses} type={this.props.type}>
					{this.props.children}
				</button>
			);
		}

		return button;
	}
}

export default Button;
